var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.channelsState.activeChannel)?_c('a-card',{staticClass:"channel-invite-links-settings"},[_c('div',{staticClass:"flex flex-col items-start gap-2 sm:flex-row sm:justify-between sm:items-center",attrs:{"slot":"title"},slot:"title"},[_c('config-field',{attrs:{"title":_vm.$t('channel_invite_links_title'),"mini-help-message":_vm.$t('channel_invite_links_mini_help_message')}}),_c('save-channel-config',{staticClass:"flex-shrink-0",attrs:{"slot":"extra"},slot:"extra"})],1),_c('a-alert',{attrs:{"show-icon":""}},[_c('extended-help-message',{attrs:{"slot":"message","show-icon":"","message":_vm.$t('invite_links_list_private_channel_description', [_vm.$store.state.channelsState.activeChannel.title]),"footer-item-buttons":"","items":{
        'invite_links_list_private_channel_description_case_1_title': _vm.$t('invite_links_list_private_channel_description_case_1_description'),
        'invite_links_list_private_channel_description_case_2_title': _vm.$t('invite_links_list_private_channel_description_case_2_description'),
        'invite_links_list_private_channel_description_case_3_title': _vm.$t('invite_links_list_private_channel_description_case_3_description'),
      }},slot:"message"})],1),_c('select-input',{attrs:{"setup":{
      'func': _vm.channelConfigSetup,
      'args': {
        'key': 'approve',
        'options': _vm.approveTypeOptions,
        'clearable': false,
        'hasAnchor': true
      }
    }}}),(_vm.$store.state.channelsState.activeChannel.config.approve !== _vm.InviteLinkApproveType.None)?_c('multi-message-editor-with-media-input',{key:"message",attrs:{"setup":{
      'func': _vm.channelConfigSetup,
      'args': {
        'key': 'auto_accepted_user_message',
        'availableButtonsTypes': _vm.autoApproveButtons(_vm.$store.state.channelsState.activeChannel.config.auto_accepted_user_message),
        'base-api-url': 'api.presscode.app/cabinet',
        'settings-structure': {
          'remove_previous': false,
          'pin': false,
          'disable_notify': false,
          'disable_link_preview': false,
          'send_after': 0,
        },
        'targetToUpload': {
          'target': 'target',
          'target_id': _vm.$store.state.boardsState.activeBoard.board
        },
        'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
        'emptyKeyboardInfo': _vm.$t('auto_accepted_user_message_empty_keyboard_info'),
        'editorMode': _vm.MediaEditorMode.TelegramShort
      }
    }}}):_vm._e(),(_vm.isAutoApprove && _vm.hasCaptcha)?_c('switch-input',{key:"force_approve",staticClass:"w-full",attrs:{"setup":{
      'func': _vm.channelConfigSetup,
      'args': {
        'key': 'force_approve',
      }
    }}}):_vm._e(),(_vm.$store.state.channelsState.activeChannel.config.approve !== _vm.InviteLinkApproveType.None)?_c('div',{key:"actions",staticClass:"flex flex-col xl:flex-row gap-5 w-full",class:{
      'xl:flex-col': _vm.hasCaptcha && _vm.isManualApprove
    }},[(_vm.hasCaptcha)?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",class:{
        'xl:w-full': _vm.hasCaptcha && _vm.isManualApprove
      },attrs:{"activation-action":_vm.user_captcha_success_action,"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.app/thumbnail/flow/" + key); },"empty-flow-button-callback":_vm.gotoFlows,"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.$t('select_editor_button_help_message'),"prefix":_vm.captchaSuccessActionPrefix,"limit-types":_vm.limitTypes},on:{"update:activationAction":function($event){_vm.user_captcha_success_action=$event},"update:activation-action":function($event){_vm.user_captcha_success_action=$event}}},[_c('a-alert',{attrs:{"slot":"no-action-info","show-icon":""},slot:"no-action-info",scopedSlots:_vm._u([{key:"message",fn:function(){return [(_vm.isAutoApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_captcha_success_action_none_auto_approve'))}}):_vm._e(),(_vm.isManualApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_captcha_success_action_none_manual_approve'))}}):_vm._e()]},proxy:true}],null,false,3962521458)})],1):_vm._e(),_c('div',{staticClass:"flex flex-col xl:flex-row gap-5 w-full",class:{
        'w-full xl:w-1/2': _vm.hasCaptcha && _vm.isAutoApprove
      }},[(_vm.isManualApprove || (_vm.isAutoApprove && _vm.hasCaptcha))?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",class:{
          'xl:w-full': _vm.hasCaptcha && _vm.isAutoApprove
        },attrs:{"activation-action":_vm.user_accept_action,"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.app/thumbnail/flow/" + key); },"empty-flow-button-callback":_vm.gotoFlows,"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.$t('select_editor_button_help_message'),"prefix":"user_accept_action_","limit-types":_vm.limitTypes},on:{"update:activationAction":function($event){_vm.user_accept_action=$event},"update:activation-action":function($event){_vm.user_accept_action=$event}}},[_c('a-alert',{attrs:{"slot":"no-action-info","show-icon":""},slot:"no-action-info",scopedSlots:_vm._u([{key:"message",fn:function(){return [(_vm.isManualApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_accept_action_select_none_action_manual_approve'))}}):_vm._e(),(_vm.isAutoApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_accept_action_select_none_action_auto_approve'))}}):_vm._e()]},proxy:true}],null,false,1298198098)})],1):_vm._e(),(_vm.isManualApprove)?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",attrs:{"activation-action":_vm.user_reject_action,"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.app/thumbnail/flow/" + key); },"empty-flow-button-callback":_vm.gotoFlows,"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.$t('select_editor_button_help_message'),"prefix":"user_reject_action_","limit-types":_vm.limitTypes},on:{"update:activationAction":function($event){_vm.user_reject_action=$event},"update:activation-action":function($event){_vm.user_reject_action=$event}}}):_vm._e()],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }